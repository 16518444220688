
import Bowser from 'bowser'

const defaultUA = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.117 Safari/537.36'
const detect = {}

const test = (ua) => {
  if (!ua) {
    if (process.browser) ua = window.navigator.userAgent
    else ua = defaultUA
  }

  const _bowser = Bowser.getParser(ua)

  const platform = _bowser.getPlatformType()
  const browser = _bowser.getBrowserName()
  const os = _bowser.getOSName()
  const model = (_bowser.getPlatform().model || '').toLowerCase()

  const hiddenIpad = process.browser && os === 'macOS' && navigator.maxTouchPoints > 1
  const brave = process.browser && !!navigator.brave
  const instagram = process.browser && navigator.userAgent.match(/instagram/i)

  return {
    desktop: platform === 'desktop' && !hiddenIpad,
    mobile: platform === 'mobile',
    tablet: platform === 'tablet' || platform === 'tv' || hiddenIpad,

    opera: browser === 'Opera' || browser === 'Opera Coast',
    safari: browser === 'Safari',
    edge: browser === 'Microsoft Edge',
    ie: browser === 'Internet Explorer',
    chrome: (browser === 'Chrome' || browser === 'Chromium') && !brave,
    firefox: browser === 'Firefox',
    uc_browser: browser === 'UC Browser',
    brave: brave,
    instagram,

    android: os === 'Android',
    ios: os === 'iOS',
    windows: os === 'Windows',
    linux: os === 'Linux',
    macos: os === 'macOS',

    iphone: model === 'iphone',
    ipad: model === 'ipad',

    result: _bowser.parsedResult
  }
}

const initDetect = (ua) => {
  Object.assign(detect, test(ua))

  if (process.browser) {
    const addClass = (valid, key) => document.documentElement.classList.add((valid ? '' : 'no-') + key)

    addClass(detect.safari, 'safari')
    addClass(detect.mobile, 'mobile')
    addClass(detect.tablet, 'tablet')
    addClass(detect.instagram, 'instagram')
    addClass(detect.desktop, 'desktop')
  }
}

initDetect()

export default detect
export { initDetect, test }
