const splice = (string, start, delCount, newSubStr) => string.slice(0, start) + newSubStr + string.slice(start + Math.abs(delCount))

const limitText = (text, limit, ellipsis = true) => {
  if (ellipsis) limit -= 3

  if (text.length > limit) {
    var trim = text.substr(0, limit)
    trim = trim.substr(0, Math.min(trim.length, trim.lastIndexOf(' ')))
    if (ellipsis) trim += '...'

    return trim
  } else {
    return text
  }
}

const padStart = (text, fill = '0', length = 2) => {
  text = '' + text
  while (text.length < length) text = fill + text
  return text
}

const spanify = (text, spanLetter = true, spanInnerLetter = false) => {
  const spnf = (t, cN) => `<span class="${cN}">${t}</span>`
  const letter = l => (spanInnerLetter ? spnf(spnf(l, 'letter'), 'inner-letter') : spnf(l, 'letter'))
  const letters = w => (spanLetter ? w.split('').map(letter).join('') : spnf(w, 'inner-word'))
  const words = w => (spnf(letters(w), 'word'))
  return text.split(' ').map(words).join(' ')
}

const _capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1)

const capitalize = (text, first = false) => {
  if (first) return _capitalize(text)
  return text.split(/(.*?(?:[ '-]|$))/g).map(_capitalize).join('')
}

const slugify = (text) => {
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

let _div
const div = () => (_div || (_div = document.createElement('div')))

const escape = (text) => {
  div().textContent = text
  div().innerText = text
  return div().innerHTML
}

const encodeURLParams = (object) => Object.entries(object)
  .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
  .join('&')

const decodeURLParams = (string) => string && string.split('&')
  .reduce((memo, key) => {
    const split = key.split('=')
    memo[decodeURIComponent(split[0])] = decodeURIComponent(split[1])
    return memo
  }, {})

export default {
  slugify,
  splice,
  limitText,
  spanify,
  capitalize,
  escape,
  padStart,
  encodeURLParams,
  decodeURLParams
}
