import reduce from 'lodash/reduce'

import text from 'helpers/text'

export const mqSizes = {}

mqSizes.small = 1200
mqSizes.tablet = 1024
mqSizes.tabletP = 900
mqSizes.phone = 740
mqSizes.phoneP = 420

export const mq = reduce(mqSizes, (memo, value, key) => {
  memo[key] = `@media all and (max-width: ${value}px)`
  memo['not' + text.capitalize(key)] = `@media all and (min-width: ${value + 1}px)`
  return memo
}, {})

mq.hover = '@media (hover: hover)'
mq.touch = '@media (hover: none)'

export default mq
